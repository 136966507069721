import { AxiosError }  from 'axios';
import { defineStore } from 'pinia';
import { ref }         from 'vue';

import OrdersApi                          from '@/api/orders-api';
import { OffersResponse, OrdersResponse } from '@/types/orders';
import { captureError }                   from '@/utils/error-logger';

const useOrderStore = defineStore('orders', () => {
    const orders = ref<Record<number, OrdersResponse>>({});
    const offers = ref<Record<number, OffersResponse>>({});

    function reset(): void {
        orders.value = {};
        offers.value = {};
    }

    async function fetchOrders(page = 1): Promise<OrdersResponse> {
        return OrdersApi.getOrders(page)
            .then(response => {
                orders.value[page] = response.data;

                return orders.value[page];
            })
            .catch((error: AxiosError) => {
                delete orders.value[page];
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function fetchOffers(page = 1): Promise<OffersResponse> {
        return OrdersApi.getOffers(page)
            .then(response => {
                offers.value[page] = response.data;

                return offers.value[page];
            })
            .catch((error: AxiosError) => {
                delete offers.value[page];
                captureError(error);

                return Promise.reject(error);
            });
    }

    return {
        orders,
        offers,
        reset,
        fetchOrders,
        fetchOffers,
    };
});

export default useOrderStore;

import axios                              from '@/api/api';
import { OffersResponse, OrdersResponse } from '@/types/orders';
import getRoute                           from '@/utils/routing';

class OrdersApi {
    async getOrders(page?: number) {
        return axios.get<OrdersResponse>(
            getRoute(
                'api_v1_account_account_orders_list',
                page !== undefined ? { page } : undefined,
            ),
        );
    }

    async getOffers(page?: number) {
        return axios.get<OffersResponse>(
            getRoute(
                'api_v1_account_account_offers_list',
                page !== undefined ? { page } : undefined,
            ),
        );
    }
}

export default (new OrdersApi());

import { storeToRefs } from 'pinia';
import {
    createRouter,
    createWebHistory,
    NavigationFailure,
    RouteLocationNormalized,
    RouteRecordRaw,
}                      from 'vue-router';

import { pushAccountPageViewEvent } from '@/utils/gtm';
import useAddressStore              from '@/vue/stores/address-store';
import useCartStore                 from '@/vue/stores/cart-store';
import useCheckoutStore             from '@/vue/stores/checkout-store';
import useCountryStore              from '@/vue/stores/country-store';
import useCustomerStore             from '@/vue/stores/customer-store';
import useOrderStore                from '@/vue/stores/order-store';
import useResellerStore             from '@/vue/stores/reseller-store';

const AccountNav = () => import('@/vue/components/account/AccountNav.vue');
const Authentication = () => import('@/vue/components/account/Authentication.vue');
const PersonalData = () => import('@/vue/components/account/PersonalData.vue');
const LoginInfo = () => import('@/vue/components/account/LoginInfo.vue');
const Addresses = () => import('@/vue/components/account/Addresses.vue');
const OrdersList = () => import('@/vue/components/account/OrdersList.vue');
const OffersList = () => import('@/vue/components/account/OffersList.vue');
const ResellerPortalProjectList = () => import('@/vue/components/account/reseller.portal/ResellerPortalProjectsList.vue');
const ProjectPage = () => import('@/vue/components/account/reseller.portal/ProjectPage.vue');
const Logout = () => import('@/vue/components/account/Logout.vue');
const ResetPassword = () => import('@/vue/components/account/ResetPassword.vue');
const SetPassword = () => import('@/vue/components/account/SetPassword.vue');
const SetPasswordPayPal = () => import('@/vue/components/account/SetPasswordPayPal.vue');

export const accountRoutes: RouteRecordRaw[] = [
    {
        path:      '/account',
        name:      'authenticate',
        component: Authentication,
        meta:      {
            showInMenu: false,
            label:      'register.title.default',
        },
    },
    {
        path:       '/account/personal-data',
        name:       'personal-data',
        components: {
            default:     PersonalData,
            leftSideBar: AccountNav,
        },
        meta:      {
            showInMenu:   true,
            label:        'my.nav.personalData',
            requiresAuth: true,
        },
    },
    {
        path:       '/account/login-info',
        name:       'login-info',
        components: {
            default:     LoginInfo,
            leftSideBar: AccountNav,
        },
        meta:       {
            showInMenu:   true,
            label:        'my.nav.loginInfo',
            requiresAuth: true,
        },
    },
    {
        path:       '/account/addresses',
        name:       'addresses',
        components: {
            default:     Addresses,
            leftSideBar: AccountNav,
        },
        meta:       {
            showInMenu:   true,
            label:        'my.nav.addresses',
            requiresAuth: true,
        },
    },
    {
        path:       '/account/orders',
        name:       'orders',
        components: {
            default:     OrdersList,
            leftSideBar: AccountNav,
        },
        meta:       {
            showInMenu:   true,
            label:        'my.nav.orders',
            requiresAuth: true,
        },
    },
    {
        path:       '/account/offers',
        name:       'offers',
        components: {
            default:     OffersList,
            leftSideBar: AccountNav,
        },
        meta:       {
            showInMenu:   true,
            label:        'my.nav.offers',
            requiresAuth: true,
        },
    },
    {
        path:       '/account/reseller',
        name:       'reseller-portal',
        components: {
            default:     ResellerPortalProjectList,
            leftSideBar: AccountNav,
        },
        meta:      {
            showInMenu: (isReseller: boolean) => isReseller,
            label:      'my.nav.reseller',
        },
    },
    {
        path:       '/account/projects/:id',
        name:       'project-details',
        components: {
            default:     ProjectPage,
            leftSideBar: AccountNav,
        },
        meta: {
            showInMenu: false,
            label:      "project-details",
        },
    },
    {
        path:      '/account/logout',
        name:      'logout',
        component: Logout,
        meta:      {
            showInMenu: true,
            label:      'my.nav.logout',
        },
    },
    {
        path:      '/account/reset-password',
        name:      'reset-password',
        component: ResetPassword,
        meta:      {
            showInMenu: false,
            label:      'reset-password',
        },
    },
    {
        path:      '/account/set-password/:passwordRecoveryCode?',
        name:      'set-password',
        component: SetPassword,
        meta:      {
            showInMenu: false,
            label:      'set-password',
        },
    },
    {
        path:      '/account/pp-set-password/:passwordRecoveryCode?',
        name:      'pp-set-password',
        component: SetPasswordPayPal,
        meta:      {
            showInMenu: false,
            label:      'pp-set-password',
        },
    },
];

const accountRouter = createRouter({
    history: createWebHistory(),
    routes:  accountRoutes,
});

// Export for testing
export async function beforeRouteFn(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
) {
    const { pageHeaderData } = storeToRefs(useCustomerStore());
    const { getPageHeaderData } = useCustomerStore();

    if ((document.referrer === '' && from.name === undefined) || pageHeaderData.value.isLoggedIn === null) {
        await getPageHeaderData()
            .catch(() => {
                // Error has already been logged ─ just catch here to silence it.
            });
    }

    if (to.name === 'logout' && pageHeaderData.value.isLoggedIn) {
        const stores = [
            useAddressStore(),
            useCartStore(),
            useCheckoutStore(),
            useCountryStore(),
            useCustomerStore(),
            useOrderStore(),
            useResellerStore(),
        ];

        for (const store of stores) {
            if (store.$id === 'customer') {
                await store.logout();
            }

            store.reset();
        }
    }

    if (to.name === 'authenticate' && pageHeaderData.value.isLoggedIn && !pageHeaderData.value.isGuestBuyer) {
        return { name: 'personal-data' };
    }

    if (to.name !== 'logout' && to.meta.requiresAuth && !pageHeaderData.value.isLoggedIn && !pageHeaderData.value.isGuestBuyer) {
        return { name: 'authenticate' };
    }
}

// Export for testing
export async function afterRouteFn(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    failure?: NavigationFailure | void,
): Promise<void> {
    if (to.matched.length === 0) {
        // We are not on an account page, so let's avoid running anything here
        return;
    }

    // 4 = ErrorTypes.NAVIGATION_ABORTED but it's not exported from vue-router
    if (failure && failure.type === 4 ) {
        return;
    }

    pushAccountPageViewEvent();

    if (to.meta.requiresAuth) {
        document.addEventListener('beforeunload', () => {
            // Empty function
            // Listener needs to exist on pages that require authentication so browsers will not load those pages from
            // their `bfcache` if user clicks Back button after logging out.
            //
            // Resources:
            // - Page Lifecycle API: https://developer.chrome.com/blog/page-lifecycle-api/#faqs
            // - Back/forward cache: https://web.dev/bfcache/
        });
    }
}

accountRouter.beforeEach(beforeRouteFn);
accountRouter.afterEach(afterRouteFn);

export { accountRouter };
